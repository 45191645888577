import Script from 'next/script';

import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Topbar, Footer } from './components';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
}));

const pages = {
  // solutions: {
  //   title: 'Soluções',
  //   id: 'solutions',
  //   children: {
  //     institutions: {
  //       groupTitle: 'Instituições de ensino',
  //       pages: [
  //         {
  //           title: 'Escolas de educação básica',
  //           href: '/solutions/k-12',
  //         },
  //         {
  //           title: 'Escolas de idiomas',
  //           href: '/solutions/language-schools',
  //         },
  //         {
  //           title: 'Faculdades e universidades',
  //           href: '/solutions/colleges',
  //         },
  //         {
  //           title: 'Grupos educacionais',
  //           href: '/solutions/educational-groups',
  //         },
  //       ],
  //     },
  //     sellers: {
  //       groupTitle: 'Fornecedores educacionais',
  //       pages: [
  //         {
  //           title: 'Editoras e sistemas de ensino',
  //           href: '/solutions/publishers',
  //         },
  //         {
  //           title: 'Cursos livres e complementares',
  //           href: '/solutions/after-school',
  //         },
  //         {
  //           title: 'Confecções, livrarias e papelarias',
  //           href: '/solutions/retail',
  //         },
  //         {
  //           title: 'Soluções e plataformas digitais',
  //           href: '/solutions/digital-platforms',
  //         },
  //       ],
  //     },
  //     extra: {
  //       groupTitle: 'Novas iniciativas',
  //       pages: [
  //         {
  //           title: 'Parcerias',
  //           href: '/solutions/partners',
  //         },
  //         {
  //           title: 'Hub de fornecedores',
  //           href: '/solutions/hub',
  //         },
  //         {
  //           title: 'Afiliados',
  //           href: '/solutions/affiliates',
  //         },
  //       ],
  //     },
  //     customers: {
  //       groupTitle: 'Consumidores educacionais',
  //       pages: [
  //         {
  //           title: 'Encontre lojas',
  //           href: '/explore',
  //         },
  //       ],
  //     },
  //   },
  // },
  // platform: {
  //   title: 'Plataforma',
  //   id: 'platform',
  //   children: {
  //     onlineSales: {
  //       groupTitle: 'Venda online',
  //       pages: [
  //         {
  //           title: 'Marketplace',
  //           href: '/platform/marketplace',
  //         },
  //         {
  //           title: 'E-commerce',
  //           href: '/platform/e-commerce',
  //         },
  //         {
  //           title: 'Seller',
  //           href: '/platform/seller',
  //         },
  //       ],
  //     },
  //     customerSupport: {
  //       groupTitle: 'Atendimento ao cliente',
  //       pages: [
  //         {
  //           title: 'Pré-venda',
  //           href: '/platform/pre-sales',
  //         },
  //         {
  //           title: 'Orçamentos',
  //           href: '/platform/budgets',
  //         },
  //         {
  //           title: 'Concierge',
  //           href: '/platform/concierge',
  //         },
  //       ],
  //     },
  //     payments: {
  //       groupTitle: 'Meio de pagamento',
  //       pages: [
  //         {
  //           title: 'Formas de pagamento',
  //           href: '/platform/payment-methods',
  //         },
  //         {
  //           title: 'Split de pagamentos',
  //           href: '/platform/payment-split',
  //         },
  //         {
  //           title: 'Conciliação',
  //           href: '/platform/payment-conciliation',
  //         },
  //       ],
  //     },
  //     integrations: {
  //       groupTitle: 'Extensões',
  //       pages: [
  //         {
  //           title: 'Integrações',
  //           href: '/platform/integrations',
  //         },
  //         {
  //           title: 'Power-ups',
  //           href: '/platform/power-ups',
  //         },
  //         {
  //           title: 'Providers',
  //           href: '/platform/providers',
  //         },
  //       ],
  //     },
  //     orders: {
  //       groupTitle: 'Modelos comerciais',
  //       pages: [
  //         {
  //           title: 'Pedido único',
  //           href: '/platform/orders',
  //         },
  //         {
  //           title: 'Mensalidades',
  //           href: '/platform/tuition',
  //         },
  //         {
  //           title: 'Contratos',
  //           href: '/platform/contracts',
  //         },
  //       ],
  //     },
  //     management: {
  //       groupTitle: 'Gestão',
  //       pages: [
  //         {
  //           title: 'Catálogo',
  //           href: '/platform/catalog',
  //         },
  //         {
  //           title: 'Promoções e cupons',
  //           href: '/platform/promotions',
  //         },
  //         {
  //           title: 'Clientes',
  //           href: '/platform/customers',
  //         },
  //         {
  //           title: 'Logística',
  //           href: '/platform/logistics',
  //         },
  //         {
  //           title: 'Preços',
  //           href: '/platform/prices',
  //         },
  //       ],
  //     },
  //   },
  // },
  theCompany: {
    title: 'Empresa',
    id: 'theCompany',
    children: {
      eskolare: {
        groupTitle: 'Eskolare',
        pages: [
          {
            title: 'Sobre',
            href: '/about',
          },
          {
            title: 'Carreiras',
            href: '/careers',
          },
          {
            title: 'Newsroom',
            href: '/newsroom',
          },
        ],
      },
      resources: {
        groupTitle: 'Recursos',
        pages: [
          {
            title: 'Blog',
            href: '/blog',
          },
          {
            title: 'Casos de sucesso',
            href: '/success-cases',
          },
          {
            title: 'Segurança e privacidade',
            href: '/security',
          },
          {
            title: 'Central de ajuda',
            href: 'https://eskolare.zendesk.com/hc/pt-br',
          },
          {
            title: 'Status',
            href: 'https://status.eskolare.com/',
          },
        ],
      },
      integrations: {
        groupTitle: 'Extensões',
        pages: [
          {
            title: 'Integrações',
            href: '/platform/integrations',
          },
          {
            title: 'Power-ups',
            href: '/platform/power-ups',
          },
          {
            title: 'Providers',
            href: '/platform/providers',
          },
        ],
      },
      legal: {
        groupTitle: 'Legal',
        pages: [
          {
            title: 'Termos de uso',
            href: '/terms',
          },
          {
            title: 'Política de privacidade',
            href: '/privacy',
          },
        ],
      },
    },
  },
};

const MainLayout = ({ children, themeToggler, themeMode }) => {
  const classes = useStyles();

  // const theme = useTheme();
  // const isMd = useMediaQuery(theme.breakpoints.up('md'), { defaultMatches: true });

  // const [openSidebar, setOpenSidebar] = useState(false);

  // const handleSidebarOpen = () => {
  //   setOpenSidebar(true);
  // };

  // const handleSidebarClose = () => {
  //   setOpenSidebar(false);
  // };

  // const open = isMd ? false : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}>
      <Script
        id="rdstation"
        src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/dbdaec1d-99be-4457-b020-6fbcfd2f207d-loader.js"
        strategy="afterInteractive"
      />
      <Script
        id="grecaptcha"
        src={`https://www.google.com/recaptcha/enterprise.js?render=${process.env.NEXT_PUBLIC_GRECAPTCHA_SITE_KEY}`}
        strategy="beforeInteractive"
      />
      <Topbar
        // onSidebarOpen={handleSidebarOpen}
        pages={pages}
        themeMode={themeMode}
        themeToggler={themeToggler}
      />
      <main>
        <Divider />
        {children}
      </main>
      <Footer pages={pages} />
      {/* <Sidebar onClose={handleSidebarClose} open={open} variant="temporary" pages={undefined} /> */}
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
};

export default MainLayout;
